import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import createAuth0Client from '@auth0/auth0-spa-js';
import {
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { GAEvent } from '../../components/Tracking';
//import MyWindowPortal from './MyWindowPortal';

import { FormattedMessage, injectIntl } from 'react-intl';
import { factory } from "@gooddata/api-client-bear";

//placeholder={intl.formatMessage({ id: "Login.form.input.username.placeholder", defaultMessage: 'Username'})}
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

class Dashboard extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      gooddataUserLogged: false,
      showWindowPortal: false,
      isPopupBlocked: false,
      outletDetails: null
    };
    this.login = this.login.bind(this)
    this.checkIfUserIsLoggedIn = this.checkIfUserIsLoggedIn.bind(this)
    this.checkIfUserIsLoggedInToAuth0 = this.checkIfUserIsLoggedInToAuth0.bind(this)
    this.fetchOutletDetails = this.fetchOutletDetails.bind(this)
  }

  async login() {
    this.setState(state => ({
      ...state,
      showWindowPortal: !state.showWindowPortal,
    }));
  }

  async componentDidMount() {
    /* if (!localStorage.getItem('gd_sso_initiated')) {
      localStorage.setItem('gd_sso_initiated', 'false')
    } */
    this._isMounted = true;
    //this.checkIfUserIsLoggedIn()
    this.fetchOutletDetails()
    // this.checkIfUserIsLoggedInToAuth0()
    GAEvent("ADMINPANEL", "Open dashboard", "DASHBOARD_PAGE_OPENED");
  }

  componentDidUpdate(prevProps) {
    // Check if managedRestaurant was empty and now has a value
    if ((!prevProps.managedRestaurant || !prevProps.managedRestaurant.slug) && 
        this.props.managedRestaurant && this.props.managedRestaurant.slug) {
      this.fetchOutletDetails();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async checkIfUserIsLoggedInToAuth0() {
    const auth0AlacarteAnalyticsClient = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_DASHBOARD_CLIENT_ID
    });
    // console.log('logout auth0 => ', auth0)
    if (auth0AlacarteAnalyticsClient) {
      console.log('user is logged in to Auth0')
    }
  }

  async checkIfUserIsLoggedIn() {
    const domain = process.env.REACT_APP_GOODDATA_DOMAIN;
    const sdk = factory({ domain });
    try {
      sleep(2000).then(async () => {
        await sdk.user.isLoggedIn()
        .then(async (isLogged) => {
          // console.log('goodata user isLogged =>', isLogged)
          //let showWindowPortal = this.state.showWindowPortal
          if(!isLogged /*&& localStorage.getItem('gd_sso_initiated') === 'false'*//*&& !this.state.showWindowPortal*/) {
            sdk.user.initiateSamlSso(window.location.href)
            // localStorage.setItem('gd_sso_initiated', 'true')
            // showWindowPortal = !this.state.showWindowPortal
          } /* else {
            localStorage.setItem('gd_sso_initiated', 'false')
          } */
          this.setState({
            gooddataUserLogged: isLogged,
            //showWindowPortal
          })
        })
        .catch((error) => console.log('error testing if gooddate user logged in => ', error))
      })
    } catch (e) {
      console.log('error gooddata logging in user =>', e)
    }
  }

  async fetchOutletDetails() {
    try {
      const { managedRestaurant } = this.props;
      console.log('managedRestaurant => ', managedRestaurant)
      if (managedRestaurant && managedRestaurant.slug) {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_PROTOCOL + "://" + process.env.REACT_APP_STRAPI_HOST}/analytics/outletdetails?restaurant=${managedRestaurant.slug}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('id_token')}`,
            }
          }
        );
        const data = await response.json();
        this.setState({ outletDetails: data });
      }
    } catch (error) {
      console.error('Error fetching outlet details:', error);
    }
  }
  
  render() {
    const { outletDetails } = this.state;
    console.log('outletDetails => ', outletDetails)

    return (
      <div style={{height: "1200px"}}>
        <Breadcrumb>
          <BreadcrumbItem>{this.props.managedRestaurant ? this.props.managedRestaurant.name : '...'}</BreadcrumbItem>
          <BreadcrumbItem active><FormattedMessage id="MyDashboard.Breadcrumbitem.MyDashboard.Label" defaultMessage="My Dashboard" /></BreadcrumbItem>
        </Breadcrumb>
        { /*
          this.state.gooddataUserLogged ?
          // true ?
          <iframe 
            title="Alacarte dashboard"
            src={process.env.REACT_APP_GOODDATA_DASHBOARD_EMBED_URL}
            width="100%" height="100%" frameBorder="0">
          </iframe> : 
          this.state.isPopupBlocked ? 
            <div>You are using a popup blocker, please do allow for popups on this page and then refresh the page. It is recommended not to use private/incognito mode in order to work on Alacarte. Thank you.</div> 
            : <div>Loading...</div>*/
        }
        {
          outletDetails && outletDetails.url ? (
            <iframe
              title="Outlet details dashboard"
              src={outletDetails.url}
              frameBorder={0}
              width="100%" 
              height="100%"
              allowTransparency
            />
          ) : this.state.isPopupBlocked ? 
          <div>You are using a popup blocker, please do allow for popups on this page and then refresh the page. It is recommended not to use private/incognito mode in order to work on Alacarte. Thank you.</div> 
          : <div>Loading...</div>
        }
      </div>
    );
  } // end of render
} // end of class

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
    managedRestaurant: state.auth.managedRestaurant
  };
}

export default injectIntl(withRouter(connect(mapStateToProps)(Dashboard)));
